
import {Options, Vue} from "vue-class-component";
import * as EmailValidator from "email-validator";

// Components
import TopHeader from "@/components/TopHeader.vue";
import SubmitButton from "@/components/form/SubmitButton.vue";
import OnScreenKeyboard from "@/components/form/OnScreenKeyboard.vue";

@Options({
    components: {
        SubmitButton, 
        TopHeader,
        OnScreenKeyboard
    }
})
export default class Emailpage extends Vue
{
    public input: string = "";
    private _resetTimer: number = 0;
    public invalidEmail: boolean = false;

    public mounted(): void
    {
        this.setResetTimer();
    }

    public async submit(): Promise<void>
    {
        // Validate email address
        if (!EmailValidator.validate(this.input))
            return void(this.invalidEmail = true);

        this.$store.commit("video/setEmail", this.input);
        this.$router.push({name: "record-page"});
    }

    public setResetTimer(): void
    {
        if (this._resetTimer)
            window.clearTimeout(this._resetTimer);

        this._resetTimer = window.setTimeout(this._resetApplication, 30000);
    }

    /**
     * Restart application from start.
     */
    private _resetApplication(): void
    {
        this.$store.commit("video/setVideo", "");
        this.$store.commit("video/setEmail", "");
        this.$router.push("/");
    }

    public beforeUnmount(): void
    {
        if (this._resetTimer)
            window.clearTimeout(this._resetTimer);
    }
};
