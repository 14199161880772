
import {Vue, Options} from "vue-class-component";

// Lib
import RecordUtility from "@/lib/RecordUtility";

// Components
import ProgressBar from "@/components/ProgressBar.vue";
import WebcamLiveFeed from "@/components/WebcamLiveFeed.vue";
import SubmitButton from "@/components/form/SubmitButton.vue";

@Options({
    components: {
        ProgressBar,
        SubmitButton,
        WebcamLiveFeed
    }
})
export default class Recordpage extends Vue
{
    public timerDuration: number = 5000;
    private _remainingTime: number = this.timerDuration + 1000;

    public recordDuration: number = 30000;
    private _remainingRecordTime: number = this.recordDuration + 1000;

    private _timerInstance?: number;
    private _recordUtility?: RecordUtility;

    public get remainingTimeInSeconds(): number{return this._remainingTime / 1000}
    public get remainingRecordTimeInSeconds(): number{return this._remainingRecordTime / 1000}
    public get currectActveRemaingingTimeInSeconds(): number{return this.countdownActive ? this.remainingTimeInSeconds : this.remainingRecordTimeInSeconds}
    public get countdownActive(): boolean{return this._remainingTime !== this.timerDuration + 1000 && this._remainingTime > 0}
    public get recordingActive(): boolean{return this._remainingRecordTime !== this.recordDuration + 1000 && this._remainingRecordTime > 0}

    /**
     * Callback when livefeed is ready.
     * 
     * @param video 
     */
    public onVideoReady(video: HTMLVideoElement): void
    {
        this._recordUtility = new RecordUtility(video);
    }

    /**
     * Start countdowntimer and start recording after timer is finished.
     */
    public startRecordTimer(): void
    {
        const onCountdown = ()=>
        {
            if (this._remainingTime > 1000)
                return void(this._remainingTime -= 1000);

            this._remainingTime -= 1000;
            clearInterval(this._timerInstance);
            this._startRecording();
        };
        
        onCountdown();
        this._timerInstance = setInterval(onCountdown, 1000);
    }

    /**
     * Start recording the video and countdown timer.
     */
    private _startRecording(): void
    {
        const onCountdown = ()=>
        {
            if (this._remainingRecordTime > 1000)
                return void(this._remainingRecordTime -= 1000);

            this.stopRecording();
        };
        
        onCountdown();
        this._timerInstance = setInterval(onCountdown, 1000);
        this._recordUtility?.startRecord();
    }

    /**
     * Stop recording video, store the video in the video store and navigate to the upload page.
     */
    public stopRecording(): void
    {
        clearInterval(this._timerInstance);
        const video = this._recordUtility?.stopRecord() as string;
        this.$store.commit("video/setVideo", video);
        this.$router.push({name: "upload-video"});
    }
};
