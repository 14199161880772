
import {Vue, Options} from "vue-class-component";
@Options({
    emits: ["ready"],
    props: {
        /**
         * Target width of the webcam resolution
         */
        width: {
            type: Number,
            default: undefined
        },
        /**
         * Target height of the webcam resolution
         */
        height: {
            type: Number,
            default: undefined
        },
        /**
         * Target framerate of the webcam capture
         */
        framerate: {
            type: Number,
            default: 60
        }
    }
})
export default class WebcamLiveFeed extends Vue
{
    public width!: number;
    public height!: number;
    public framerate!: number;
    
    public isErrored: boolean = false;
    
    public async mounted(): Promise<void>
    {
        const mediaConstraints: MediaStreamConstraints = {
            audio: true,
            video: {
                width: this.width,
                height: this.height,
                frameRate: this.framerate,
            },
        };

        let stream: MediaStream;
        try {stream = await navigator.mediaDevices.getUserMedia(mediaConstraints)}
        catch (error){return void(this.isErrored = true)}

        const videoElement: HTMLVideoElement = this.$refs.videoFeed as HTMLVideoElement;
        videoElement.srcObject = stream;

        this.$emit("ready", videoElement);
    }
};
