export default class RecordUtility
{
    private _stream: MediaStream;
    private _video: HTMLVideoElement;

    private _chunks: Blob[] = [];
    private _recorder!: MediaRecorder;

    public constructor(video: HTMLVideoElement)
    {
        this._video = video;
        this._stream = video.srcObject as MediaStream;
    }

    /**
     * Take a screenshot of the current frame of the video element.
     * 
     * @param width Width of the image in pixels
     * @param height Height of the image in pixels
     * @returns JPEG image as base64 string
     */
    public async takeScreenshot(width?: number, height?: number): Promise<string>
    {
        const canvas = document.createElement("canvas");
        canvas.width = width ?? this._video.videoWidth;
        canvas.height = height ?? this._video.videoHeight;

        const context = canvas.getContext("2d");
        if (!context)
            throw new Error("Failed to get canvas context");
        
        context.drawImage(this._video, 0, 0, width ?? this._video.videoWidth, height ?? this._video.videoHeight);
        return canvas.toDataURL("image/jpeg");
    }

    /**
     * Start recording the mediastream of the video element.
     * @param timeslice The amount of milliseconds between storing blobs
     */
    public startRecord(timeslice: number = 100): void
    {
        if (this._chunks.length > 0)
            throw new Error("RecordUtility already recording");

        this._recorder = new MediaRecorder(this._stream, {mimeType: "video/webm;codecs=H264"});
        this._recorder.ondataavailable = (event: BlobEvent)=> this._chunks.push(event.data);
        this._recorder.start(timeslice);
    }

    /**
     * Stop the recording and return the video as base64 string.
     * 
     * @returns The video as base64 string
     */
    public stopRecord(): string
    {
        this._recorder.stop();
        const blob: Blob = new Blob(this._chunks, {type: "video/webm"});
        this._chunks = [];

        return URL.createObjectURL(blob);
    }
};