import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78bec307"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!!_ctx.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.to,
        class: _normalizeClass(["submit-button", {disabled: _ctx.disabled}])
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode("OK")
            ], true)
          ])
        ]),
        _: 3
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: "submit-button",
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, [
        _createElementVNode("p", null, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode("OK")
          ], true)
        ])
      ], 8, _hoisted_1))
}