
import {mapState} from "vuex";
import {Vue, Options} from "vue-class-component";

// Components
import TopHeader from "@/components/TopHeader.vue";

@Options({
    components: {
        TopHeader 
    },
    computed: mapState("video", {
        email(state: VuexState): string{return state.email},
        video(state: VuexState): string{return state.video}
    })
})
export default class Uploadpage extends Vue
{
    public video!: string;
    public email!: string;
    public isLoading: boolean = true;

    public mounted(): void
    {
        this._uploadVideo();

        setTimeout(this._resetApplication, 10000);
    }

    private async _uploadVideo(): Promise<void>
    {
        this.isLoading = true;

        // Create blob from the base64 string
        let posterBlob: Blob;
        try {posterBlob = await this._base64ToBlob(this.video)}
        catch (error){return console.log("Failed to convert to blob", error)}

        const body = new FormData();
        body.append("email", this.email);
        body.append("speech", posterBlob);
        
        fetch(`${process.env.VUE_APP_BACKEND}/upload-speech`, {method: "POST", body})
            .catch(console.error);

        this.isLoading = false;
    }

    private async _base64ToBlob(base64: string): Promise<Blob>
    {
        const response = await fetch(base64);
        return await response.blob();
    }

    /**
     * Restart application from start.
     */
    private _resetApplication(): void
    {
        this.$store.commit("video/setVideo", "");
        this.$store.commit("video/setEmail", "");
        this.$router.push("/");
    }
};
