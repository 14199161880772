export default {
    namespaced: true,
    state: {
        email: "",
        video: ""
    },
    getters: {
        hasEmail(state: VuexState): boolean
        {
            return !!state.email;
        },
        hasVideo(state: VuexState): boolean
        {
            return !!state.video;
        }
    },
    mutations: {
        setEmail(state: VuexState, option: string): void
        {
            state.email = option;
        },
        setVideo(state: VuexState, option: string): void
        {
            state.video = option;
        }
    }
};