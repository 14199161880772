import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

// Pages
import HomePage from "@/pages/HomePage.vue";
import EmailPage from "@/pages/Emailpage.vue";
import Recordpage from "@/pages/Recordpage.vue";
import Uploadpage from "@/pages/Uploadpage.vue";

const routes: Array<RouteRecordRaw> = [{
    path: "/",
    name: "home",
    component: HomePage,
},
{
    path: "/email-invullen",
    name: "email-page",
    component: EmailPage
},
{
    path: "/video-opnemen",
    name: "record-page",
    component: Recordpage
},
{
    path: "/video-uploaden",
    name: "upload-video",
    component: Uploadpage
}];

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
});

export default router;