
import {Vue, Options} from "vue-class-component";

@Options({
    props: {
        duration: {
            type: Number,
            required: true
        }
    }
})
export default class ProgressBar extends Vue
{
    public duration!: number;
};
