
import {Vue, Options} from "vue-class-component";

// Components
import TopHeader from "@/components/TopHeader.vue";
import SubmitButton from "@/components/form/SubmitButton.vue";

@Options({
    components: {
        TopHeader,
        SubmitButton
    }
})
export default class HomePage extends Vue
{
};
