
import {Vue, Options} from "vue-class-component";

// Types
import type {RouteLocationRaw} from "vue-router";

@Options({
    emits: ["click"], 
    props: {
        to: {
            type: Object as ()=> RouteLocationRaw, 
            required: false
        }, 
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
})
export default class SubmitButton extends Vue
{
    public to!: RouteLocationRaw;
    public disabled!: boolean;
};
